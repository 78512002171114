import React, { useRef, useState, useEffect } from 'react';

import { Autocomplete, DrawingManager, GoogleMap, Polygon, useJsApiLoader, Polyline, Marker } from '@react-google-maps/api';

import deleteIcon from '../assets/images/remove.png';

import cropicon from '../assets/images/crop.png';

import html2canvas from "html2canvas";
import axios from 'axios';
import Geocode from "react-geocode";
import { useNavigate } from "react-router-dom";
import { Alert } from 'react-bootstrap';


const libraries = ['places', 'drawing'];

const MapComponent = () => {

  const UPLOAD_ENDPOINT = "https://api-floor-planning.dealopia.com/detectpolygon";

  // const UPLOAD_ENDPOINT = "http://192.168.1.146:5001/detectpolygon";

  const navigate = useNavigate();
  const mapRef = useRef();
  const polygonRefs = useRef([]);
  const polylineRefs = useRef([]);
  const activePolygonIndex = useRef();
  const activePolylineIndex = useRef();
  const autocompleteRef = useRef();
  const drawingManagerRef = useRef();
  const key = process.env.REACT_APP_MAP_KEY;
  console.log(key)
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: key,
    libraries
  });

  const defaultCenter = {
    lat: 13.8496252,
    lng: 15.9760448
  }

  const [markerPosition, setMarkerPosition] = useState(null);
  const [center, setCenter] = useState(defaultCenter);
  const [polygons, setPolygons] = useState([]);
  const [polylines, setPolylines] = useState([]);
  const [errormsg, setErrormsg] = useState();
  const [apiprocess, setApiprocess] = useState();
  const google = window.google;

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter({ lat: latitude, lng: longitude });
          setMarkerPosition({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error('error', error);
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser');
    }
  }, []);

  const containerStyle = {
    width: '100%',
    height: '550px',
  }

  const polygonOptions = {
    fillOpacity: 0.3,
    fillColor: '#ff0000',
    strokeColor: '#ff0000',
    backgroundColor: '#ff0000',
    strokeWeight: 2,
    draggable: true,
    editable: true
  }

  const polylineOptions = {
    strokeColor: '#00008B',
    strokeOpacity: 1,
    strokeWeight: 2,
    icons: [{
      icon: "hello",
      offset: '0',
      repeat: '10px'
    }],
    draggable: true,
    editable: true
  }

  const drawingManagerOptions = {
    polygonOptions: polygonOptions,
    drawingControl: true,
    drawingControlOptions: {
      position: window.google?.maps?.ControlPosition?.TOP_CENTER,
      drawingModes: [
        window.google?.maps?.drawing?.OverlayType?.POLYGON,
        window.google?.maps?.drawing?.OverlayType?.POLYLINE,
      ]
    }
  }

  const onLoadPolygon = (polygons, index) => {
    polygonRefs.current[index] = polygons;
  }

  const onClickPolygon = (index) => {
    activePolygonIndex.current = index;
  }
  const onClickPolyline = (index) => {
    activePolylineIndex.current = index;
  }

  const onLoadPolyline = (polylines, index) => {
    polylineRefs.current[index] = polylines;
  }

  const onLoadAutocomplete = (autocomplete) => {

    autocompleteRef.current = autocomplete;
  }

  const onPlaceChanged = () => {
    if (autocompleteRef.current) {
      const place = autocompleteRef.current.getPlace();
      if (!place.geometry) {
        console.log("Returned place contains no geometry");
        return;
      }

      const bounds = new window.google.maps.LatLngBounds();
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }

      if (mapRef.current) {
        mapRef.current.fitBounds(bounds);
      } else {
        console.error('mapRef.current is undefined');
      }

      const location = place.geometry.location;
      setCenter({ lat: location.lat(), lng: location.lng() });
      setMarkerPosition({ lat: location.lat(), lng: location.lng() });
    }
  }



  const onLoadDrawingManager = drawingManager => {
    drawingManagerRef.current = drawingManager;
  }

  const onOverlayComplete = ($overlayEvent) => {

    drawingManagerRef.current.setDrawingMode(null);
    if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYGON) {
      const newPolygon = $overlayEvent.overlay.getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }))
      const startPoint = newPolygon[0];
      newPolygon.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setPolygons([...polygons, newPolygon]);
    } else if ($overlayEvent.type === window.google.maps.drawing.OverlayType.POLYLINE) {
      const newPolylines = $overlayEvent.overlay.getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      const startPoint = newPolylines[0];

      newPolylines.push(startPoint);
      $overlayEvent.overlay?.setMap(null);
      setPolylines([...polylines, newPolylines]);
    }
  }

  const onDeleteDrawing = () => {

    const filtered = polygons.filter((polygon, index) => index !== activePolygonIndex.current)
    setPolygons(filtered)
    const polylines_filtered = polylines.filter((polylines, index) => index !== activePolylineIndex.current)
    setPolylines(polylines_filtered);

  }

  const onEditPolygon = (index) => {

    const polygonRef = polygonRefs.current[index];
    if (polygonRef) {
      const coordinates = polygonRef.getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      const allPolygons = [...polygons];
      allPolygons[index] = coordinates;
      setPolygons(allPolygons)
    }

  }

  const onEditPolyline = (index) => {
    const polylineRef = polylineRefs.current[index];
    if (polylineRef) {
      const coordinate = polylineRef.getPath()
        .getArray()
        .map(latLng => ({ lat: latLng.lat(), lng: latLng.lng() }));
      const allPolylines = [...polylines];
      allPolylines[index] = coordinate;
      setPolylines(allPolylines)
    }
  }

  const detectedimages = (domElement) => {


    localStorage.clear();
    if (polygons.length === 0) {

      setErrormsg('Please Select Areas');
      return;
    } else if (polygons.length === 1) {
      setErrormsg('Please Select Buildable Areas');
      return;
    } else if (polylines.length === 0) {
      setErrormsg('Please Select Front Of The Areas');
      return;
    } else {

      setErrormsg('');
      setApiprocess('Rendering in process, It will take a few Second...');
      const element = document.getElementById('map_area');
      html2canvas(element).then(canvas => {
        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');
        link.href = data;

        const headers = {
          "access-control-allow-origin": "*",
          "content-type": "application/json"
        }

        const parameter_data = {
          "polygondata": polygons,
          "polylinedata": polylines
        }

        axios.post(UPLOAD_ENDPOINT, parameter_data, { headers }).then(res => {
          setApiprocess('');
          navigate('/captured_image', { state: { img_url: res.data.img_url, buildable_file: res.data.buildable_file } });
        });
      });
    }
  }

  const canvasRef = useRef();
  const [heading, setHeading] = useState(0);
  const [map, setMap] = useState(null);

  const onLoad = (map) => {
    setMap(map);
    mapRef.current = map;
    map.setMapTypeId('satellite');
  };

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setMarkerPosition({ lat, lng });

    Geocode.fromLatLng(lat, lng).then((response) => {
      const address = response.results[0].formatted_address;
      console.log(address);
    }, (error) => {
      console.log('error', error);
    });
  };
  const [show, setShow] = useState(true);
  return (
    isLoaded
      ?
      <div className='map-container' id="map_area" style={{ position: 'relative' }} >
        {
          drawingManagerRef.current
          &&
          <div
            onClick={onDeleteDrawing}
            title='Delete Shape'
            className="deleteIconStyle"
          >
          </div>
        }

        <GoogleMap
          onLoad={onLoad}
          id="buildingOrientation"
          mapTypeId="satellite"
          onClick={handleMapClick}
          zoom={15}
          center={center}
          mapContainerStyle={containerStyle}
          options={{
            rotateControl: true,
            draggable: true,
            mapTypeControl: true,
            streetViewControl: true,
            fullscreenControl: true,
            tilt: 0,
          }}
        >
          <DrawingManager
            onLoad={onLoadDrawingManager}
            onOverlayComplete={onOverlayComplete}
            options={drawingManagerOptions}
          />
          {
            polygons.map((iterator, index) => (
              <Polygon
                key={index}
                onLoad={(event) => onLoadPolygon(event, index)}
                onMouseDown={() => onClickPolygon(index)}
                onMouseUp={() => onEditPolygon(index)}
                onDragEnd={() => onEditPolygon(index)}
                options={polygonOptions}
                paths={iterator}
                draggable
                editable
              />
            ))
          }
          {
            polylines.map((iterator, index) => (
              <Polyline
                key={index}
                geodesic={true}
                path={iterator}
                onMouseDown={() => onClickPolyline(index)}
                options={polylineOptions}
                draggable
                editable
              />
            ))
          }
          <Autocomplete
            onLoad={onLoadAutocomplete}
            onPlaceChanged={onPlaceChanged}
          >
            <input
              type='text'
              placeholder='Search Your Location'
              className="autocompleteStyle"
            />
          </Autocomplete>

          {markerPosition && (
          <Marker
            position={markerPosition}
            draggable={true}
            onDragEnd={(e) => {
              const lat = e.latLng.lat();
              const lng = e.latLng.lng();
              setMarkerPosition({ lat, lng });
            }}
          />
        )}

        </GoogleMap>
        <button className='btn-theme next' onClick={() => detectedimages(canvasRef.current)} >Next</button>
        <div className="map-alert">
          {errormsg ? <Alert className="alert alert-danger errorclass" onClose={() => setErrormsg()} dismissible>{errormsg}</Alert> : ''}
          {/* {errormsg?<Alert className="alert alert-danger errorclass" onClose={() => setShow(false)} dismissible>{errormsg}</Alert>:''}  */}
          {apiprocess ? <Alert className="alert alert-success successclass" onClose={() => setApiprocess()} dismissible>{apiprocess}</Alert> : ''}
        </div>

      </div>
      :
      null
  );
}

export default MapComponent; 
