import React, { Fragment, useState, useEffect } from 'react';
import { Button, Form, Image, FormGroup, Container, Row, Col } from 'react-bootstrap';
import "./App.css";
import loader from './assets/loading-gif.gif';
import { useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const File_upload = () => {
  const APIBASE_URL = "https://api-floor-planning.dealopia.com/";

  const [file, setFile] = useState(null);
  const [input_image, setInputimage] = useState('');
  const [output_image, setOutputimage] = useState(null);
  const [matching_percentage, setMatchpercentage] = useState(null);
  const [matching_category, setMatch_cat] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imgData, setImgData] = useState(null);
  const [output_img_src, setOutput_img_src] = useState('');
  const location = useLocation();
  const [res_image, setImage_api_res] = useState('');
  const [city_api_res, setCity_api_res] = useState('');
  const [cityoffsetdata, setCityoffsetdata] = useState('');

  useEffect(() => {
    localStorage.setItem('output_img_src', location.state.img_url);
    localStorage.setItem('buildable_file', location.state.buildable_file);
    setOutput_img_src(localStorage.getItem('output_img_src'));

    const headers = {
      "access-control-allow-origin": "*",
      "content-type": "application/json"
    }

    const parameter_data = {
      "city_name": 'Arroyo Grande'
    }

    axios.post(APIBASE_URL + 'getcity_resisdential_list', parameter_data, { headers }).then(res => {
      setCity_api_res(res.data);
      console.log('city_api_res==', city_api_res);
    });
  }, []);

  const [value, setValue] = useState();

  const handleChange = (e) => {
    console.log(e.target.value);
    if (e.target.value !== '') {
      setValue(e.target.value);
      const headers2 = {
        "access-control-allow-origin": "*",
        "content-type": "application/json"
      }
      const parameter_data2 = {
        "id": e.target.value
      }
      console.log(parameter_data2);
      axios.post(APIBASE_URL + 'getcity_offset', parameter_data2, { headers2 }).then(res => {
        setCityoffsetdata(res.data);
        console.log('setCityoffsetdata==', cityoffsetdata);
      });
    } else {
      setCityoffsetdata('');
      setValue('');
    }
  };

  const uploaded_file = (e) => {
    setLoading(true);
    e.preventDefault();
    const headers = {
      "access-control-allow-origin": "*",
      "content-type": "application/json"
    }

    const parameter_data = {
      "input_file": localStorage.getItem('buildable_file')
    }

    axios.post(APIBASE_URL + 'graphplan', parameter_data, { headers }).then(res => {
      setLoading(false);
      setInputimage(res.data.buildable_image);
      setOutputimage(res.data.base_path + res.data.output_img);
      setMatchpercentage(res.data.matching_percentage);
      setMatch_cat(res.data.matching_category);

      localStorage.setItem('graph2planimg', res.data.output_img);

      // console.log('res.data.output_img=====',res.data.output_img);
      // window.location.assign("https://floorplanner.pagekite.me/"+res.data.output_img);
    });
  }

  return (
    <Fragment>
      <div className='page-wrapper'>
        <Container>
          <Row>
            <Col className="d-flex" md={6} lg={{ span: 6 }}>
              <div className='notes img-box'>
                <h3 className='mt-0 mb-4'>Rendered Plot Area Of The House With Offset</h3>
                <Image className='item' src={output_img_src ? output_img_src : ''}></Image>
                <div className="notes">
                  <div className='buildable item'>Buildable Area</div>
                  <div className='outer item'>Outer Area</div>
                  <div className='direction item'>Front Side</div>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6}>
              <div className='img-box'>
                <h3 className='mt-0 mb-4'>Address : Arroyo Grande</h3>
                <Form className='house-type' onSubmit={uploaded_file}>
                  <FormGroup className="mb-5">
                    <Form.Control className='form-control' as="select" value={value} onChange={handleChange}>
                      {city_api_res.length > 0 ? <option value="">Select House Type</option> : <option value=''>Type Is Not Available</option>}
                      {city_api_res.length > 0 ?
                        city_api_res.map((citylist, index) =>
                          <option key={index} value={citylist.id}>{citylist.residential_type}</option>
                        )
                        : ''
                      }
                    </Form.Control>
                    <Button className="ms-2 btn-theme" type="submit" disabled={loading ? "disabled" : value ? "" : "disabled"} variant="primary">
                      {loading ? <>Loading..</> : <>Submit</>}
                    </Button>
                  </FormGroup>
                </Form>

                {loading ? <div className="d-flex justify-content-center">
                  <div className='img-box d-flex align-items-center justify-content-center'>
                    <div className="text-center">
                      <p>Rendering in process,<br /> It will take a few minutes...</p>
                      <Image className='loader-img' src={loader}></Image>
                    </div>
                  </div>
                </div> :
                  <div className='house-typedetail'>
                    {cityoffsetdata ?
                      <div className='setbackcss'>
                        <h5>City Name :-</h5>
                        <p><b>{cityoffsetdata.city_name}</b></p>
                        <h5>Offset Details:-</h5>
                        <p><b>Front Side:</b> {cityoffsetdata.front_setback} inches</p>
                        <p><b>Back Side:</b> {cityoffsetdata.back_setback} inches</p>
                        <p><b>Side-Side:</b> {cityoffsetdata.side_setback} inches</p>
                      </div>
                      : ''
                    }
                  </div>
                }
                {output_image && (
                  <div className='output-img'>
                    <h3 className='mt-4'>Output Image</h3>
                    <Image src={output_image} alt="Output" fluid />
                    <p>Matching Percentage: {matching_percentage}</p>
                    {/* <p>Matching Category: {matching_category}</p> */}
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
}

export default File_upload;
